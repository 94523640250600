export default {
    name: "SelectInput",
    props: {
        choices: {},
        value: {},
        placeholder: {},
        label: {},
        classes: {},
        allowEmpty: {},
    }
};
