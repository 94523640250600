var PATTERNS = {
    'games:details': '/games/{0}/',
    'games:genre': '/games/genre/{0}/',
    'games:platform': '/games/platform/{0}/',
};
export function urlFor(pattern) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    var template = PATTERNS[pattern];
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match;
    });
}
export function urlMedia(name) {
    return "/media/" + name;
}
