export default {
    name: "ButtonComponent",
    props: {
        name: {
            default: "Ok"
        },
        classes: {},
        href: {
            default: '#'
        }
    }
};
