export var PLATFORMS = [
    { "id": 'pc', "name": 'PC' },
    { "id": 'playstation-4', "name": 'PlayStation 4' },
    { "id": 'nintendo-switch', "name": 'Nintendo Switch' },
    { "id": 'xbox-one', "name": 'Xbox One' },
];
export var GENRES = [
    {
        "id": "adventure",
        "name": "Приключения"
    },
    {
        "id": "role-playing",
        "name": "Ролевая"
    },
    {
        "id": "platform",
        "name": "Платформа"
    },
    {
        "id": "shooter",
        "name": "Шутер"
    },
    {
        "id": "indie",
        "name": "Инди"
    },
    {
        "id": "turn-based",
        "name": "Пошаговая"
    },
    {
        "id": "point-and-click",
        "name": "Point-and-Click"
    },
    {
        "id": "hack-and-slash",
        "name": "Hack and Slash / Beat ’em up"
    },
    {
        "id": "strategy",
        "name": "Стратегия"
    },
    {
        "id": "puzzle",
        "name": "Пазл"
    },
    {
        "id": "tactical",
        "name": "Тактика"
    },
    {
        "id": "arcade",
        "name": "Аркада"
    },
    {
        "id": "racing",
        "name": "Гонки"
    },
    {
        "id": "visual-novel",
        "name": "Визуальная новелла"
    },
    {
        "id": "simulator",
        "name": "Симулятор"
    },
    {
        "id": "fighting",
        "name": "Драки"
    },
    {
        "id": "sports",
        "name": "Спорт"
    },
    {
        "id": "music-rhythm",
        "name": "Музыка"
    },
    {
        "id": "real-time-strategy-rts",
        "name": "Стратегии в реальном времени"
    }
];
export var SORTING_OPTIONS = [
    { "id": "-main_story_avg", "name": "По убыванию времени прохождения" },
    { "id": "main_story_avg", "name": "По возрастанию времени прохождения" },
    { "id": "-release_date", "name": "По убыванию даты релиза" },
    { "id": "release_date", "name": "По возрастанию даты релиза" },
];
