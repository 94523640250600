import Vue from 'vue';
import { urlFor, urlMedia } from "../../urls";
import TagsList from "../base/tags/list";
export default Vue.extend({
    name: "GameCard",
    components: { TagsList: TagsList },
    props: {
        game: {}
    },
    methods: {
        urlMedia: urlMedia,
        urlFor: urlFor,
    },
    computed: {
        detailsUrl: function () {
            // @ts-ignore
            return this.urlFor('games:details', this.game.slug);
        }
    }
});
