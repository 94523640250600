import Vue from 'vue';
export default Vue.extend({
    name: "InputComponent",
    props: {
        placeholder: {
            default: ""
        },
        name: {},
        value: {},
        label: {},
        type: {
            default: "number"
        }
    },
    methods: {
        emitChange: function (value) {
            if (this.type === 'number') {
                value = value.replace(/,/g, '.');
                value = value.replace(/[^\d\.]/g, "");
            }
            this.$emit('change', value);
            // @ts-ignore
            this.$refs.input.value = value;
        }
    }
});
