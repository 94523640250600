import Vue from 'vue';
import { urlFor } from "../../../urls";
var Component = Vue.extend({
    name: "TagsList",
    props: {
        size: {
            default: 'medium'
        },
        items: {},
        entity: {}
    },
    computed: {
        classes: function () {
            var _a;
            var sizeKey = "is-" + this.size;
            return _a = {},
                _a[sizeKey] = true,
                _a;
        }
    },
    methods: {
        urlFor: urlFor
    }
});
export default Component;
