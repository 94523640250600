import Vue from 'vue';
import { GENRES, PLATFORMS, SORTING_OPTIONS } from './../../constants';
import SelectInput from './../forms/inputs/selectinput.vue';
import ButtonComponent from './../forms/buttons/button.vue';
import InputComponent from './../forms/inputs/input.vue';
import GamesList from './../games/list.vue';
import { buildQuery } from "../../utils/urls";
var Component = Vue.extend({
    name: "GamesFilter",
    data: function () {
        var queryFilters = Object.assign({}, this.defaultQuery);
        return {
            platforms: PLATFORMS,
            genres: GENRES,
            sortingOptions: SORTING_OPTIONS,
            queryFilters: queryFilters,
            offset: 0,
            games: [],
            isLoading: false,
        };
    },
    props: {
        defaultQuery: {
            default: {},
        },
        withGames: {
            default: false,
        },
        G_HORIZONTAL: {
            default: "",
        }
    },
    components: { SelectInput: SelectInput, ButtonComponent: ButtonComponent, InputComponent: InputComponent, GamesList: GamesList },
    methods: {
        updateQueryFilter: function (key, value) {
            this.queryFilters[key] = value;
        },
    },
    computed: {
        searchHref: function () {
            var query = buildQuery(this.queryFilters);
            return "/games/?" + query;
        }
    }
});
export default Component;
